<div>
  <nav [tabPanel]="tabPanel" class="tabs" mat-tab-nav-bar>
    <a [active]="activeLink === 'list'"
       [routerLink]="['list']"
       mat-tab-link
       routerLinkActive="active">
      Lista
    </a>
    <a [active]="activeLink === 'upload'"
       [routerLink]="['upload']"
       mat-tab-link
       routerLinkActive="active">
      Certifikat
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>