import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {IAddCertificateRequest, ICertData, ICertificate, ICertInfo, ICertService} from '@sparbanken-syd/cert-backend'

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * This can be set to hold the last installed cert if wanted.
   * After install we set this if the user wants to.
   */
  public lastCert: ICertData | undefined = {
    'subject': 'CN=*.internal.sparbankensyd.se',
    'validTo': 1728431999000,
    'validFrom': 1694131200000,
    'issuer': 'C=US\nO=DigiCert Inc\nOU=www.digicert.com\nCN=RapidSSL TLS RSA CA G1',
    'san': 'DNS:*.internal.sparbankensyd.se, DNS:internal.sparbankensyd.se',
    'serialNumber': '0C3002EEC35E57D00C6576CB4A9BF418'
  }

  constructor(
    private http: HttpClient
  ) {
  }

  public getServices(): Observable<ICertService[]> {
    return this.http.get<ICertService[]>(`${environment.apiUrl}/services`)
  }

  public getCertificates(account: string): Observable<ICertificate[]> {
    return this.http.get<ICertificate[]>(`${environment.apiUrl}/certificates/${account}`)
  }

  /**
   * This is the function that installs a new cert.
   * @param input
   */
  public uploadCertificate(input: IAddCertificateRequest): Observable<ICertData> {
    return this.http.put<ICertData>(`${environment.apiUrl}/certificates`, input)
  }

  public parseCertificate(input: string): Observable<ICertData> {
    return this.http.put<ICertData>(`${environment.apiUrl}/parse`, {cert: input})
  }

  public saveCertInfo(info: ICertInfo): Observable<ICertInfo> {
    let url: string = `${environment.apiUrl}/info`
    if(info.id) {
      url = `${url}/${info.id}`
    }
    return this.http.put<ICertInfo>(url, info)
  }

  public deleteCertInfo(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/info/${id}`)
  }

  public getAllCertsInfo(): Observable<Required<ICertInfo>[]> {
    return this.http.get<any>(`${environment.apiUrl}/info`)
  }
}
