<div class="mat-elevation-z8 table-holder">
  <mat-form-field>
    <mat-label>Filtrera på tjänst</mat-label>
    <input [formControl]="filter" matInput>
  </mat-form-field>
  <table [dataSource]="dataSource" mat-table matSort>
    <caption [style.display]="'none'">Desc</caption>
    <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
    <tr *matRowDef="let r; columns: columnsToDisplay" mat-row></tr>

    <ng-container matColumnDef="service">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="service">Tjänst</th>
      <td *matCellDef="let element"
          mat-cell>
        <div class="service-holder">
          <div (click)="editCertInfo(element)" class="table-row">{{element.service}}</div>
          <a *ngIf="element.accountNumber" [routerLink]="['..', 'upload', element.accountNumber, element.fqdn]"
             mat-icon-button>
            <mat-icon>double_arrow</mat-icon>
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="typeOfCertificate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="typeOfCertificate">Typ av certifikat
      </th>
      <td (click)="editCertInfo(element)" *matCellDef="let element" class="table-row"
          mat-cell>{{translations.get(element.typeOfCertificate)}}</td>
    </ng-container>

    <ng-container matColumnDef="supplier">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="supplier">Leverantör
      </th>
      <td (click)="editCertInfo(element)" *matCellDef="let element" class="table-row"
          mat-cell>{{translations.get(element.supplier)}}</td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="startDate">Startdatum
      </th>
      <td (click)="editCertInfo(element)" *matCellDef="let element" class="table-row date-cell"
          mat-cell>{{element.startDate | date: 'yyyy-MM-dd'}}</td>

    </ng-container>

    <ng-container matColumnDef="endDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="endDate">Slutdatum</th>
      <td (click)="editCertInfo(element)" *matCellDef="let element" class="table-row date-cell" mat-cell>
        {{element.endDate | date: 'yyyy-MM-dd'}}
      </td>
    </ng-container>


    <ng-container matColumnDef="certificateSerialNumber">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="certificateSerialNumber">Certifikatserienummer
      </th>
      <td (click)="editCertInfo(element)" *matCellDef="let element" class="table-row serial-cell"
          mat-cell>{{element.certificateSerialNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="deleteItem">
      <th *matHeaderCellDef mat-header-cell style="width:10%;"></th>
      <td *matCellDef="let element" class="text-align-center delete-icon" mat-cell>
        <button (click)="deleteCertInfo(element)" mat-icon-button>
          <mat-icon color="warn">delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr *matNoDataRow class="no-data">
      <td [attr.colspan]="columnsToDisplay.length" class="no-data">
        <ng-container *ngIf="!dataLoaded;else noFilter">Hämtar data</ng-container>
        <ng-template #noFilter>Inget matchar ditt filter</ng-template>
      </td>
    </tr>
  </table>
  <button
      (click)="addCertInfo()"
      color="primary"
      mat-raised-button>Lägg till
  </button>
</div>
