<div matDialogTitle>Ett fel har inträffat</div>

<div matDialogContent>
  <p>Vänligen försök igen</p>
  <p>Detaljerad information som ni kan delge Linaf:</p>
  <div class="error">
    {{data}}
  </div>
</div>

<div matDialogActions>
  <button [mat-dialog-close]="undefined" color="primary" mat-raised-button>Stäng</button>
</div>