<div class="spb-plain-content home">
  <div class="service-select">
    <mat-form-field>
      <mat-label>Välj tjänst</mat-label>
      <mat-select
          [formControl]="serviceCtrl"
          ngDefaultControl placeholder="Välj först tjänst">
        <mat-option *ngFor="let service of services$ | async" [value]="service">
          {{service.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="serviceCtrl.value" class="fqdn">Tjänstens url:
      <a [href]="'https://' + serviceCtrl.value.fqdn" target="_blank">https://{{serviceCtrl.value.fqdn}}</a></div>
    <mat-form-field>
      <mat-label>Välj certifikat</mat-label>
      <mat-select
          [formControl]="certListControl"
          [placeholder]="certPlaceHolder" ngDefaultControl>
        <mat-select-trigger>
          {{certListControl.value?.fqdn}}
        </mat-select-trigger>
        <mat-option [value]="newCert">{{newCert.fqdn}}</mat-option>
        <mat-option *ngFor="let cert of certificates$ | async" [value]="cert">
          {{cert.fqdn}} <span class="fqdn">Utgår: {{cert.end | date: 'yyyy-MM-dd'}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <form [formGroup]="keyForm">
      <mat-form-field>
        <mat-label>Certifikat</mat-label>
        <textarea
            cdkAutosizeMaxRows="20"
            cdkTextareaAutosize
            formControlName="cert"
            matInput></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nyckel</mat-label>
        <textarea
            cdkAutosizeMaxRows="20"
            cdkTextareaAutosize
            formControlName="key"
            matInput></textarea>
      </mat-form-field>
    </form>
    <div *ngIf="certInfo">
      <div>Subject: {{certInfo.subject}}</div>
      <div>Issuer: {{certInfo.issuer}}</div>
      <div>Valid to: {{certInfo.validTo | date:'yyyy-MM-dd'}}</div>
      <div>SAN: {{certInfo.san}}</div>
      <div>Serial number: {{certInfo.serialNumber}}</div>
    </div>
  </div>
  <button (click)="save()" [disabled]="keyForm.invalid" color="primary" mat-raised-button>Spara</button>
</div>