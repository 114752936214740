import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {DataService} from '../../../services/data.service'

@Component({
  selector: 'spb-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dataService: DataService
  ) {
  }

  public deleteCert(): void {
    this.dataService.deleteCertInfo(this.data).subscribe({
      next: () => {
        this.dialogRef.close(true)
      }
    })
  }
}
