import {Component, OnInit} from '@angular/core'
import {Event, NavigationEnd, Router} from '@angular/router'
import {filter} from 'rxjs/operators'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public activeLink: string = 'list'

  constructor(
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.activeLink = this.router.url.split('/')[2]
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    )
      .subscribe({
        next: (event: NavigationEnd) =>
          this.activeLink = event.url.split('/')[2]
      })
  }
}
