<div class="form-holder">
    <form [formGroup]="certListForm">
        <mat-form-field>
            <mat-label>Tjänst</mat-label>
            <textarea
                    matInput
                    [formControl]="certListForm.controls.service"
                    cdkAutosizeMaxRows="20"
                    cdkTextareaAutosize></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Typ av certifikat</mat-label>
            <mat-select [formControl]="certListForm.controls.typeOfCertificate" ngDefaultControl>
                <mat-option *ngFor="let cert of certificateTypes | keyvalue" [value]="cert.key">
                    {{cert.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Leverantör</mat-label>
            <mat-select [formControl]="certListForm.controls.supplier" ngDefaultControl>
                <mat-option *ngFor="let supplier of suppliers | keyvalue" [value]="supplier.key">
                    {{supplier.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Startdatum</mat-label>
            <input [matDatepicker]="startDate" [formControl]="certListForm.controls.startDate" matInput>
            <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>


        <mat-form-field>
            <mat-label>Slutdatum</mat-label>
            <input [matDatepicker]="endDate" [formControl]="certListForm.controls.endDate" matInput>
            <mat-datepicker-toggle [for]="endDate" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Certifikatserienummer</mat-label>
            <textarea
                    matInput
                    [formControl]="certListForm.controls.certificateSerialNumber"
                    cdkAutosizeMaxRows="20"
                    cdkTextareaAutosize></textarea>
        </mat-form-field>
    </form>

    <button
            [disabled]="!certListForm.valid"
            color="primary"
            mat-raised-button (click)="save()">Spara
    </button>
    <button
            mat-raised-button mat-dialog-close>Avbryt
    </button>

</div>