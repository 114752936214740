import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {DataService} from '../../services/data.service'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {ICertInfo} from '@sparbanken-syd/cert-backend'
import {certificateTypes, suppliers} from '../types'
import {DateTime} from 'luxon'

@Component({
  selector: 'spb-cert-dialog',
  templateUrl: './cert-dialog.component.html',
  styleUrls: ['./cert-dialog.component.scss']
})
export class CertDialogComponent implements OnInit {

  public certListForm = new FormGroup({
    id: new FormControl<string>('', {nonNullable: true}),
    service: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    typeOfCertificate: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    supplier: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    startDate: new FormControl<DateTime | null>(null, {validators: [Validators.required]}),
    endDate: new FormControl<DateTime | null>(null, {validators: [Validators.required]}),
    certificateSerialNumber: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    // These two are not visible
    accountNumber: new FormControl<string | undefined>(undefined, {nonNullable: true}),
    fqdn: new FormControl<string | undefined>(undefined, {nonNullable: true})
  })

  public certificateTypes: Map<string, string> = new Map(certificateTypes)
  public suppliers: Map<string, string> = new Map(suppliers)

  constructor(
    public dialogRef: MatDialogRef<CertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICertInfo | undefined,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.certListForm.patchValue({
        ...this.data,
        endDate: DateTime.fromMillis(this.data.endDate),
        startDate: DateTime.fromMillis(this.data.startDate)
      })
    }
  }

  public save() {
    const cert = {
      ...this.certListForm.getRawValue(),
      startDate: this.certListForm.controls.startDate.value!.toMillis(),
      endDate: this.certListForm.controls.endDate.value!.toMillis()
    }

    this.dataService.saveCertInfo(cert).subscribe({
      next: () => {
        this.dialogRef.close(cert)
      }
    })
  }
}

