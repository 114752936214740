import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {HomeComponent} from './home/home.component'
import {CertListComponent} from './cert-list/cert-list.component'
import {UploadComponent} from './upload/upload.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'list',
        component: CertListComponent
      },
      {
        path: 'list/:account',
        component: CertListComponent
      },
      {
        path: 'upload',
        component: UploadComponent
      },
      {
        path: 'upload/:account/:fqdn',
        component: UploadComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
