import {Component, OnInit} from '@angular/core'
import {BehaviorSubject, NEVER, switchMap, tap} from 'rxjs'
import {IAddCertificateRequest, ICertData, ICertificate, ICertService} from '@sparbanken-syd/cert-backend'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {DataService} from '../../services/data.service'
import {MatDialog} from '@angular/material/dialog'
import {catchError, filter} from 'rxjs/operators'
import {WaitDialogComponent} from '../wait/wait-dialog.component'
import {HttpErrorResponse} from '@angular/common/http'
import {ErrorComponent} from '../error/error.component'
import {InstallComponent} from './install/install.component'
import {ActivatedRoute, ParamMap} from '@angular/router'

@Component({
  selector: 'spb-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  public services$ = new BehaviorSubject<ICertService[]>([])

  public serviceCtrl = new FormControl<ICertService | null>(null)

  public certificates$ = new BehaviorSubject<ICertificate[]>([])

  public certListControl = new FormControl<ICertificate | null>({
    value: null, disabled: true
  })

  /**
   * Placeholder for a new certificate
   */
  public newCert: ICertificate = {end: 0, start: 0, fqdn: 'Nytt certifikat', san: []}

  public keyForm = new FormGroup({
    cert: new FormControl<string | null>('', {validators: [Validators.required]}),
    key: new FormControl<string | null>('', {validators: [Validators.required]})
  })

  public certInfo: ICertData | undefined

  public certPlaceHolder = ''

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit() {
    this.dataService.getServices()
      .subscribe({
        next: (services: ICertService[]) => {
          services.sort((a, b) => a.name.localeCompare(b.name))
          this.services$.next(services)
          this.setAccountSelection()
        }
      })

    this.serviceCtrl.valueChanges
      .pipe(
        filter((s: ICertService | null): s is ICertService => s !== null),
        switchMap((service: ICertService) => {
          this.certListControl.disable()
          this.certPlaceHolder = 'Hämtar certifikat'
          return this.dataService.getCertificates(service.accountNumber)
        })
      )
      .subscribe({
        next: (certificates: ICertificate[]) => {
          this.certificates$.next(certificates)
          this.certListControl.enable()
          this.certPlaceHolder = 'Välj certifikat'
          this.setCertSelection()
        }
      })

    this.keyForm.controls.cert.valueChanges.pipe(
      tap(() => this.certInfo = undefined),
      filter((val: string | null): val is string => !!val),
      filter((val: string) => val.length > 500),
      switchMap((val: string) => {
        return this.dataService.parseCertificate(val).pipe(
          // We must catch this inner or the main subscription dies too.
          catchError((e: HttpErrorResponse) => {
            this.dialog.open(ErrorComponent, {
              data: e.error.errorMessage
            })
            return NEVER
          })
        )
      })
    ).subscribe({
      next: (val: ICertData) => {
        this.certInfo = val
      }
    })
  }

  public save(): void {
    let ref = this.dialog.open(WaitDialogComponent, {disableClose: true})

    const input: IAddCertificateRequest = {
      accountNumber: this.serviceCtrl.value?.accountNumber as string,
      arn: this.certListControl.value?.arn,
      certificate: window.btoa(this.keyForm.controls.cert.value as string),
      cloudFrontId: this.serviceCtrl.value?.cloudFrontId as string,
      key: window.btoa(this.keyForm.controls.key.value as string)
    }

    this.dataService.uploadCertificate(input)
      .pipe(
        switchMap((cert: ICertData) => {
          this.dataService.lastCert = cert
          ref.close()
          ref = this.dialog.open<InstallComponent, string, boolean>(InstallComponent, {
            disableClose: true,
            data: this.serviceCtrl.value?.accountNumber
          })
          this.resetFields()
          return ref.afterClosed()
        }),
        catchError((e: HttpErrorResponse) => {
          ref.close()
          this.dialog.open(ErrorComponent, {
            data: e.error.errorMessage
          })
          return NEVER
        }),
        filter((res: boolean) => !res)
      )
      .subscribe({
        next: () => {
          // If no addition is asked for we reset the installed cert.
          this.dataService.lastCert = undefined
        }
      })
  }

  private setAccountSelection() {
    this.route.paramMap
      .subscribe({
        next: (map: ParamMap) => {
          if (map.has('account')) {
            this.serviceCtrl.setValue(
              this.services$.value.find((ics: ICertService) => ics.accountNumber === map.get('account') as string) as ICertService)
          }
        }
      })
  }

  private setCertSelection(): void {
    this.route.paramMap
      .subscribe({
        next: (map: ParamMap) => {
          if (map.has('fqdn')) {
            this.certListControl.setValue(
              this.certificates$.value.find((ics: ICertificate) => ics.fqdn === map.get('fqdn') as string) as ICertificate)
          }
        }
      })
  }

  private resetFields(): void {
    this.keyForm.reset()
    this.certListControl.reset()
    this.serviceCtrl.reset()
    this.certInfo = undefined
  }
}
