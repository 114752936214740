import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HomeComponent} from './home/home.component'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {HomeRoutingModule} from './home-routing.module'
import {MatSelectModule} from '@angular/material/select'
import {ReactiveFormsModule} from '@angular/forms'
import {MatInputModule} from '@angular/material/input'
import {MatButtonModule} from '@angular/material/button'
import {WaitDialogComponent} from './wait/wait-dialog.component'
import {MatDialogModule} from '@angular/material/dialog'
import {MatTabsModule} from '@angular/material/tabs'
import {CertListComponent} from './cert-list/cert-list.component'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'
import {ErrorComponent} from './error/error.component'
import {MatTableModule} from '@angular/material/table'
import {MatSortModule} from '@angular/material/sort'
import {MatIconModule} from '@angular/material/icon'
import {ConfirmDialogComponent} from './cert-list/confirm-dialog/confirm-dialog.component'
import {LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter'
import {UploadComponent} from './upload/upload.component'
import {InstallComponent} from './upload/install/install.component'


@NgModule({
  declarations: [
    HomeComponent,
    WaitDialogComponent,
    CertListComponent,
    ErrorComponent,
    ConfirmDialogComponent,
    UploadComponent,
    InstallComponent
  ],
  imports: [
    CommonModule,
    ThemeModule,
    HomeRoutingModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatDatepickerModule,
    MatTableModule,
    MatSortModule,
    MatIconModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, firstDayOfWeek: 1}},
    {provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS}
  ]
})
export class HomeModule {
}
