import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'spb-install',
  templateUrl: './install.component.html'
})
export class InstallComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public accountNo: string) {
  }
}
