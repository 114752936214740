import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core'
import {FormControl} from '@angular/forms'
import {MatDialog} from '@angular/material/dialog'
import {MatSort} from '@angular/material/sort'
import {MatTableDataSource} from '@angular/material/table'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {ICertData, ICertInfo} from '@sparbanken-syd/cert-backend'
import {filter, startWith} from 'rxjs/operators'
import {CertDialogComponent} from '../../common/cert-dialog/cert-dialog.component'
import {certificateTypes, suppliers} from '../../common/types'
import {DataService} from '../../services/data.service'
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component'

@Component({
  selector: 'spb-cert-list',
  templateUrl: './cert-list.component.html',
  styleUrls: ['./cert-list.component.scss']
})
export class CertListComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSort) sort: MatSort = {} as any

  public translations = new Map<string, string>(certificateTypes.concat(suppliers))

  public columnsToDisplay: string[] = ['service', 'typeOfCertificate', 'supplier', 'startDate', 'endDate',
    'certificateSerialNumber', 'deleteItem']

  public dataSource: MatTableDataSource<ICertInfo> = new MatTableDataSource<ICertInfo>()
  /**
   * Controls the empty message.
   */
  public dataLoaded = false

  public filter = new FormControl('', {nonNullable: true})

  private certInfoList = new Map<string, ICertInfo>()
  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (map: ParamMap) => {
        if (map.has('account')) {
          const cert: ICertData = {...this.dataService.lastCert} as ICertData
          this.editCertInfo({
            service: '',
            supplier: 'linaf',
            typeOfCertificate: 'ssl',
            startDate: cert.validFrom,
            endDate: cert.validTo,
            certificateSerialNumber: cert.serialNumber,
            // This must be set properly by the requester.
            accountNumber: map.get('account') as string,
            // Subject happens to be in the form 'CN=*.hello.service.com'
            fqdn: this.dataService.lastCert?.subject.split('=')[1]
          })
        }
      }
    })
    this.filter.valueChanges
      .pipe(startWith(''))
      .subscribe({
      next: (filter: string) => {
        this.dataSource.filter = filter.trim().toLowerCase()
      }
    })
    this.loadData()
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }

  public loadData() {
    this.dataService.getAllCertsInfo().subscribe({
      next: (certInfoList: Required<ICertInfo>[]) => {
        this.certInfoList = new Map<string, ICertInfo>(certInfoList.map((certInfo: Required<ICertInfo>) => [certInfo.id, certInfo]))
        this.sortSetList()
        this.dataLoaded = true
      }
    })
  }

  public editCertInfo(cert: ICertInfo): void {
    this.dialog.open(CertDialogComponent, {
      data: cert
    }).afterClosed().pipe(
      filter(Boolean)
    )
      .subscribe({
        next: (cert: Required<ICertInfo>) => {
          this.certInfoList.set(cert.id, cert)
          this.sortSetList()
        }
      })
  }

  public addCertInfo(): void {
    this.dialog.open(CertDialogComponent).afterClosed().pipe(
      filter(Boolean)
    )
      .subscribe({
        next: (cert: Required<ICertInfo>) => {
          this.certInfoList.set(cert.id, cert)
          this.sortSetList()
        }
      })
  }

  public deleteCertInfo(cert: Required<ICertInfo>): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: cert.id
    })
      .afterClosed().pipe(
      filter(Boolean)
    ).subscribe({
      next: () => {
        this.certInfoList.delete(cert.id)
        this.sortSetList()
      }
    })
  }

  private sortSetList(): void {
    const data: ICertInfo[] = [...this.certInfoList.values()]
    data.sort((a: ICertInfo, b: ICertInfo) => a.service.localeCompare(b.service))

    this.dataSource.data = data
  }
}
