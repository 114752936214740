export const certificateTypes: any = [
  ['ssl', 'SSL'],
  ['relyingParty', 'Förlitandepart'],
  ['ticket', 'Ticket'],
  ['signature', 'Signering'],
  ['bankCertificate', 'Bankintyg']
]

export const suppliers: any = [
  ['sdc', 'SDC'],
  ['linaf', 'Linaf'],
  ['telia', 'Telia'],
  ['nets', 'Nets'],
  ['getSwish', 'GetSwish'],
  ['tieto', 'Tieto'],
  ['expisoft', 'Expisoft'],
  ['convertor', 'Convertor'],
  ['tillvaxt', 'Tillväxtsyd']
]
